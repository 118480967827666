const TOKEN_KEY = 'biztapauth'
const USER_SESSION_KEY = 'biztapuser'
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 * * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {

  getToken() {
    if (process.browser) {
      return localStorage.getItem(TOKEN_KEY)
    }
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
    //  Apiservice.setHeader()
  },

  saveUserData(userData) {
    localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userData))
    //  Apiservice.setHeader()
  },
  getUserData() {
    // if (process.browser) {
    return JSON.parse(localStorage.getItem(USER_SESSION_KEY))
  },
  getUserType() {
    // if (process.browser) {
    let user = JSON.parse(localStorage.getItem(USER_SESSION_KEY))
    if (user) {
      return user.user_type
    }
    return 0

  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
    //  Apiservice.removeHeader()
  },
  removeUserData() {
    localStorage.removeItem(USER_SESSION_KEY)
    //  Apiservice.removeHeader()
  }

}

export default TokenService
