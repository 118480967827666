import Vue from "vue";
import Vuex from "vuex";
import TokenService from "./token_service";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: TokenService.getToken() || '',
    userData: TokenService.getUserData() || {}
  },
  mutations: {
    LoginSuccess(state, token) {
      state.token = token
      TokenService.saveToken(token)
    },
    setUserData(state, UserData) {
      state.userData = UserData
      TokenService.saveUserData(UserData)
    },
    Logout(state) {
      state.token = ''
      TokenService.removeToken()
      TokenService.removeUserData()
      console.log('logout successful')
    }
  }
});