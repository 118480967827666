export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/admin/overview'
  },
  {
    name:'Accounts',
    icon:'nc-icon nc-circle-10',
    children:[
      {
        name:'Active Accounts',
        path: '/accounts/listaccounts'

      },
      {
        name:'List New Users',
        path: '/accounts/assigncoupon'
  
      },
    ]
  },
  {
    name:'Profiles',
    icon:'nc-icon nc-circle-10',
    children:[
      {
        name:'List Profiles',
        path: '/profiles/listprofiles'

      },]
    },
  
  
]
