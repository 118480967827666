import { render, staticRenderFns } from "./CalendarRoute.vue?vue&type=template&id=2f88b2c9"
import script from "./CalendarRoute.vue?vue&type=script&lang=js"
export * from "./CalendarRoute.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports