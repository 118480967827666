<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>
                  <div v-if="error == true" class="alert alert-danger alert-dismissible fade show">
                    <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close">
                      <i class="nc-icon nc-simple-remove"></i>
                    </button>
                    <span>Invalid Credentials</span>
                  </div>
                  <div class="card-body">
                    <ValidationProvider
                      name="email"
                      rules="required|email"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input  type="email"
                                :error="failed ? 'The Email field is required': null"
                                :hasSuccess="passed"
                                label="Email address"
                                name="User Name"
                                v-model="form.username"
                                @click="setErrorFalse">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      name="password"
                      rules="required|min:5"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input  type="password"
                                :error="failed ? 'The Password field is required': null"
                                :hasSuccess="passed"
                                name="password"
                                label="Password"
                                v-model="form.password"
                                @click="setErrorFalse">
                      </fg-input>
                    </ValidationProvider>

                  </div>
                  <div class="card-footer text-center">
                    <p-button type="button" native-type="submit" @click="validate_login">Login</p-button>
                  </div>        
                </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { mapState } from "vuex";
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";

  extend("email", email);
  extend("required", required);
  extend("min", min);


  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      
    },
    computed: {
    ...mapState({
        products: (state) => state.products
      })
    },
    methods: {
      setErrorFalse(){
        console.log('error occured')
        this.error = false
      },
      validate_login(){
        this.$http.post('dashboard/adm_login',
        {
          'admin_email': this.form.username,
          'admin_password': this.form.password
        })
        .then(response => {
        let res = response.body
        
        if(res.data){
          let user = res.data.user_info;
          let token = res.data.token;
          this.$store.commit("LoginSuccess", token);
          this.$store.commit("setUserData", user);
          this.$router.push('/admin')
        }
        else{
          this.error = true
        }
        })
      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login() {
        // handle login here
      }
    },
    data() {
      return {
        
        error : false,
        form: {
          username: '',
          password: ''
        }
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
