<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        
      </nav>
      <div class="copyright pull-right">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by <a href="https://www.riosis.com/" rel="noopener" target="_blank">Riosis Innovations Private Limited.</a>.</span>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
